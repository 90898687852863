export const clpText = "#9E9E9E99";
export const clpTextLight = "#9E9E9E55";
export const clpBgLight = "#9E9E9E22";
export const d3Text = "rgb(161, 14, 197,0.45)";
export const d3TextLight = "rgb(161, 14, 197,0.35)";
export const d3BgLight = "rgb(161, 14, 197,0.2)";
export const descoText = "#FF980099";
export const descoTextLight = "#FF980077";
export const descoBgLight = "#FF980044";
export const epdText = "#1DE9B699";
export const epdTextLight = "#1DE9B666";
export const epdBgLight = "#1DE9B633";
export const eventsText = "#00968888";
export const eventsTextLight = "#00968866";
export const eventsBgLight = "#00968833";
export const hpaText = "#E91E6388";
export const hpaTextLight = "#E91E6366";
export const hpaBgLight = "#E91E6333";
export const parishodText = "#4CAF5099";
export const parishodTextLight = "#4CAF5055";
export const parishodBgLight = "#4CAF5022";
export const schoolText = "#3F51B599";
export const schoolTextLight = "#3F51B566";
export const schoolBgLight = "#3F51B533";
export const umangText = "#FFC10799";
export const umangTextLight = "#FFC10777";
export const umangBgLight = "#FFC10744";